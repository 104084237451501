<template>
  <dashboard-layout>
    <div>Teste</div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
export default {
  name: 'Edit',
  components: { DashboardLayout }
};
</script>

<style scoped></style>
